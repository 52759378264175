export const MAIN_PANEL = 'main';
export const LAYOUT_PANEL = 'layout';

export const PANEL_HEIGHTS = {
  POST_LIST_WIDGET_MAIN_SETTINGS: 410,
  POST_LIST_WIDGET_LAYOUT_SETTINGS: 120,
  ARCHIVE_LIST_WIDGET_MAIN_SETTINGS: 220,
  ARCHIVE_LIST_WIDGET_LAYOUT_SETTINGS: 120,
  CATEGORY_LIST_WIDGET_MAIN_SETTINGS: 240,
  CATEGORY_LIST_WIDGET_LAYOUT_SETTINGS: 120,
  POST_TICKER_WIDGET_MAIN_SETTINGS: 600, // todo - max-height reached, waiting for editor to resolve it
  POST_TICKER_WIDGET_LAYOUT_SETTINGS: 120,
};
