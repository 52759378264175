const MANAGING_ELEMENTS_HELP_ID = 'cbcc3945-f2e0-4347-ad75-fe2c32c6819e';
const REPEATER_ITEM_HELP_ID = '9cc6c484-46ea-4aef-8067-0fe3be5ca72a';
const IMAGE_HELP_ID = '41a182e0-3b77-4731-af2f-38c6dbf6e982';
const TEXT_HELP_ID = 'a6046c00-b434-4068-97c0-d5bc472c691d';

const helpPanelIds = {
  MANAGING_ELEMENTS_HELP_ID,
  REPEATER_ITEM_HELP_ID,
  IMAGE_HELP_ID,
  TEXT_HELP_ID,
};

export default helpPanelIds;
