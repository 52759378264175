import { blogAppDefId } from './apps';

export const BLOG_WIDGET_ID = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6';
export const POST_WIDGET_ID = '211b5287-14e2-4690-bb71-525908938c81';

export const POST_LIST_WIDGET_TYPE = `${blogAppDefId}-arqe5`;
export const ARCHIVE_LIST_WIDGET_TYPE = `${blogAppDefId}-d94yd`;
export const CATEGORY_LIST_WIDGET_TYPE = `${blogAppDefId}-otga2`;
export const POST_TICKER_WIDGET_TYPE = `${blogAppDefId}-qlgsh`;

export const POST_LIST_WIDGET_ID = 'd9744c45-2cc4-45d6-bece-e7d5ad41f692';
export const ARCHIVE_LIST_WIDGET_ID = 'c7721f7e-c605-465c-af47-7e277abc752e';
export const CATEGORY_LIST_WIDGET_ID = 'c319bbe1-3703-4d8f-944e-92790316072c';
export const POST_TICKER_WIDGET_ID = '48097e3f-b22c-4aa4-931d-0f29efa15066';
