import { LAYOUT_PANEL, PANEL_HEIGHTS } from '../constants/settings-panels';
import { isLayoutPanel } from '../selectors/settings-panels-selectors';
import {
  POST_LIST_WIDGET_TYPE,
  ARCHIVE_LIST_WIDGET_TYPE,
  CATEGORY_LIST_WIDGET_TYPE,
  POST_TICKER_WIDGET_TYPE,
} from '../constants/widgets';
import { WIDGET_APP_SETTINGS_URL } from '../constants/api';
import { getControllerType } from '../selectors/editor-sdk-components-data';
import { getEditorSDKVersion } from './editor-sdk';
import translation from './translation';

const getPanelHeight = ({ controllerType, panelType }) => {
  switch (controllerType) {
    case POST_LIST_WIDGET_TYPE:
      return isLayoutPanel(panelType)
        ? PANEL_HEIGHTS.POST_LIST_WIDGET_LAYOUT_SETTINGS
        : PANEL_HEIGHTS.POST_LIST_WIDGET_MAIN_SETTINGS;
    case ARCHIVE_LIST_WIDGET_TYPE:
      return isLayoutPanel(panelType)
        ? PANEL_HEIGHTS.ARCHIVE_LIST_WIDGET_LAYOUT_SETTINGS
        : PANEL_HEIGHTS.ARCHIVE_LIST_WIDGET_MAIN_SETTINGS;
    case CATEGORY_LIST_WIDGET_TYPE:
      return isLayoutPanel(panelType)
        ? PANEL_HEIGHTS.CATEGORY_LIST_WIDGET_LAYOUT_SETTINGS
        : PANEL_HEIGHTS.CATEGORY_LIST_WIDGET_MAIN_SETTINGS;
    case POST_TICKER_WIDGET_TYPE:
      return isLayoutPanel(panelType)
        ? PANEL_HEIGHTS.POST_TICKER_WIDGET_LAYOUT_SETTINGS
        : PANEL_HEIGHTS.POST_TICKER_WIDGET_MAIN_SETTINGS;
    default:
      return;
  }
};

const getActivePanelType = ({ sdk, panelType }) => {
  switch (panelType) {
    case LAYOUT_PANEL:
      return sdk.editor.PanelType.Layout;
    default:
      return;
  }
};

const getPanelTitle = ({ controllerType, panelType }) => {
  switch (controllerType) {
    case ARCHIVE_LIST_WIDGET_TYPE:
    case CATEGORY_LIST_WIDGET_TYPE:
    case POST_TICKER_WIDGET_TYPE:
    case POST_LIST_WIDGET_TYPE:
      return isLayoutPanel(panelType)
        ? translation.translate('app-manifest.layout')
        : translation.translate('app-manifest.settings');
    default:
      return translation.translate('app-manifest.widget-settings');
  }
};

const getPanelUrl = ({ panelType, sdk, instance, componentRef, componentRole }) => {
  let url = `${WIDGET_APP_SETTINGS_URL}/?instance=${instance}&sdkVersion=${getEditorSDKVersion(sdk)}&compId=${
    componentRef.id
  }&panelType=${panelType}`;

  if (componentRole) {
    url = `${url}&componentRole=${componentRole}`;
  }

  return url;
};

export const getPanelOptions = async ({ sdk, instance, componentRef, panelType, componentRole }) => {
  const componentData = await sdk.components.data.get('token', { componentRef });
  const controllerType = getControllerType(componentData);
  const height = getPanelHeight({ controllerType, panelType });
  const type = getActivePanelType({ sdk, panelType });

  return {
    url: getPanelUrl({ panelType, sdk, instance, componentRef, componentRole }),
    title: getPanelTitle({ controllerType, panelType }),
    ...(type && { type }),
    ...(height && { height }),
  };
};
