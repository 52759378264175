import {
  POST_LIST_WIDGET_TYPE,
  ARCHIVE_LIST_WIDGET_TYPE,
  CATEGORY_LIST_WIDGET_TYPE,
  POST_TICKER_WIDGET_TYPE,
} from '../constants/widgets';
import { OPEN_WIDGET_MAIN_SETTINGS } from '../constants/events';
import helpPanelIds from '../constants/help-panel-ids';
import { actionTypes, actions, dataEditOptions } from '../constants/gfpp';
import translation from './translation';

const createPostListWidgetGFPP = () => ({
  default: {
    displayName: translation.translate('app-manifest.post-list'),
    gfpp: {
      desktop: {
        mainAction1: {
          label: translation.translate('app-manifest.settings'),
          actionId: OPEN_WIDGET_MAIN_SETTINGS,
        },
        iconButtons: {
          background: actions.HIDE,
        },
        helpId: helpPanelIds.MANAGING_ELEMENTS_HELP_ID,
      },
    },
    connections: {
      stateBox: {
        gfpp: {
          desktop: {
            iconButtons: {
              settings: actions.HIDE,
              design: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      postList: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
              layout: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      emptyStateText: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
      postImage: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.design'),
              actionType: actionTypes.DESIGN,
            },
            iconButtons: {
              crop: actions.HIDE,
              link: actions.HIDE,
              settings: actions.HIDE,
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          resizable: false,
        },
      },
      listItem: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
              design: actions.HIDE,
              animation: actions.HIDE,
            },
            helpId: helpPanelIds.REPEATER_ITEM_HELP_ID,
          },
        },
      },
      postTitle: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
      postDate: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
    },
  },
});

const createCategoryListWidgetGFPP = () => ({
  default: {
    displayName: translation.translate('app-manifest.category-list'),
    gfpp: {
      desktop: {
        mainAction1: {
          label: translation.translate('app-manifest.settings'),
          actionId: OPEN_WIDGET_MAIN_SETTINGS,
        },
        iconButtons: {
          background: actions.HIDE,
        },
        helpId: helpPanelIds.MANAGING_ELEMENTS_HELP_ID,
      },
    },
    connections: {
      stateBox: {
        gfpp: {
          desktop: {
            iconButtons: {
              settings: actions.HIDE,
              design: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      categoryList: {
        gfpp: {
          desktop: {
            iconButtons: {
              layout: actions.HIDE,
              connect: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      // todo update naming
      container1: {
        gfpp: {
          desktop: {
            iconButtons: {
              animation: actions.HIDE,
              design: actions.HIDE,
              connect: actions.HIDE,
            },
          },
        },
      },
      emptyStateText: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
      listItem: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.design'),
              actionType: actionTypes.DESIGN,
            },
            iconButtons: {
              layout: actions.HIDE,
              connect: actions.HIDE,
              background: actions.HIDE,
              link: actions.HIDE,
            },
          },
        },
      },
    },
  },
});

const createPostTickerWidgetGFPP = () => ({
  default: {
    displayName: translation.translate('app-manifest.post-ticker'),
    gfpp: {
      desktop: {
        mainAction2: {
          label: translation.translate('app-manifest.settings'),
          actionId: OPEN_WIDGET_MAIN_SETTINGS,
        },
        iconButtons: {
          background: actions.HIDE,
        },
        helpId: helpPanelIds.MANAGING_ELEMENTS_HELP_ID,
      },
    },
    connections: {
      stateBox: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.design'),
              actionType: actionTypes.DESIGN,
            },
            iconButtons: {
              settings: actions.HIDE,
              design: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      next: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.design'),
              actionType: actionTypes.DESIGN,
            },
            iconButtons: {
              connect: actions.HIDE,
              link: actions.HIDE,
              settings: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      prev: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.design'),
              actionType: actionTypes.DESIGN,
            },
            iconButtons: {
              connect: actions.HIDE,
              link: actions.HIDE,
              settings: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      emptyStateText: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
      postImage: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.design'),
              actionType: actionTypes.DESIGN,
            },
            iconButtons: {
              crop: actions.HIDE,
              link: actions.HIDE,
              settings: actions.HIDE,
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          resizable: false,
        },
      },
      postTitle: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
      postDate: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
    },
  },
});

const createArchiveListWidgetGFPP = () => ({
  default: {
    displayName: translation.translate('app-manifest.archive-list'),
    gfpp: {
      desktop: {
        mainAction1: {
          label: translation.translate('app-manifest.settings'),
          actionId: OPEN_WIDGET_MAIN_SETTINGS,
        },
        iconButtons: {
          background: actions.HIDE,
        },
        helpId: helpPanelIds.MANAGING_ELEMENTS_HELP_ID,
      },
    },
    connections: {
      stateBox: {
        gfpp: {
          desktop: {
            iconButtons: {
              settings: actions.HIDE,
              design: actions.HIDE,
              animation: actions.HIDE,
            },
          },
        },
      },
      archiveList: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.layout'),
              actionType: actionTypes.LAYOUT,
            },
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
      },
      emptyStateText: {
        gfpp: {
          desktop: {
            iconButtons: {
              connect: actions.HIDE,
            },
          },
        },
        behavior: {
          dataEditOptions: dataEditOptions.TEXT_STYLE_ONLY,
        },
      },
      listItem: {
        gfpp: {
          desktop: {
            mainAction1: {
              label: translation.translate('app-manifest.layout'),
              actionType: actionTypes.LAYOUT,
            },
            iconButtons: {
              connect: actions.HIDE,
              background: actions.HIDE,
              link: actions.HIDE,
            },
          },
        },
      },
    },
  },
});

export const createWidgetGFPP = () => ({
  [POST_LIST_WIDGET_TYPE]: createPostListWidgetGFPP(),
  [ARCHIVE_LIST_WIDGET_TYPE]: createCategoryListWidgetGFPP(),
  [CATEGORY_LIST_WIDGET_TYPE]: createPostTickerWidgetGFPP(),
  [POST_TICKER_WIDGET_TYPE]: createArchiveListWidgetGFPP(),
});

export default {
  createWidgetGFPP,
};
