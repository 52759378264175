import { get } from 'lodash';

export const getEditorSDKVersion = (sdk) =>
  sdk.info
    .getSdkVersion()
    .scriptSrc.split('/')
    .find((part) => part.match(/^\d\.\d{1,5}\.\d{1,5}$/) !== null);

export const getAssetsUrl = (options) => {
  const editorScriptUrl = get(options, 'initialAppData.editorScriptUrl', '');
  return `${
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:33666'
      : editorScriptUrl.substring(0, editorScriptUrl.lastIndexOf('/'))
  }/assets`;
};
