import { SECTIONS } from './sections';

const dependentApps = {
  [SECTIONS.ABOUT]: {
    appDefinitionId: '14dbef06-cc42-5583-32a7-3abd44da4908',
    pageId: 'about',
    method: 'addApplication',
    loginMenuTitle: 'Profile',
    showInLoginMenu: true,
    menuOrder: 1,
    socialHome: true,
    urlOverride: 'profile',
  },
  [SECTIONS.FOLLOWERS]: {
    appDefinitionId: '14ebe801-d78a-daa9-c9e5-0286a891e46f',
    pageId: 'following_followers',
    method: 'addApplication',
    showInMemberMenu: false,
    urlOverride: 'followers',
    menuOrder: 2,
  },
  [SECTIONS.NOTIFICATIONS]: {
    appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
    pageId: 'notifications_app',
    numbers: {
      key: 'notificationsCount',
      default: 0,
    },
    method: 'addApplication',
    menuOrder: 4,
    social: false,
    urlOverride: 'notifications',
  },
  [SECTIONS.SETTINGS]: {
    appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
    pageId: 'settings',
    method: 'addApplication',
    menuOrder: 4,
    social: false,
    urlOverride: 'settings',
  },
};

export default dependentApps;
