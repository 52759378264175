export const dataEditOptions = {
  FULL: 'FULL',
  TEXT_STYLE_ONLY: 'TEXT_STYLE_ONLY',
};
export const actions = {
  HIDE: 'HIDE',
  DEFAULT: 'DEFAULT',
};

export const actionTypes = {
  DESIGN: 'design',
  LAYOUT: 'layout',
  ANIMATION: 'animation',
};
